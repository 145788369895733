<script>
import { toRefs, reactive } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getApp } from "firebase/app";

export default function() {
  let state = reactive({
    user: null,
    loading: true,
    error: null
  });

  // make the firebase call to listen for change in auth state,
  // we have set initial loading status to true so nothing happens on UI 
  // side until loading is set to false
  const firebaseApp = getApp();
  const auth = getAuth(firebaseApp);
  onAuthStateChanged(auth, _user => {
    if (_user) {
      state.user = _user;
    } else {
      state.user = null;
    }
    state.loading = false;
  });

  // return all of the properties from the function
  return {
    ...toRefs(state)
  };
}
</script>
