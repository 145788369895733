<template>
  <div class="page">
    <div class="page-body">
      <h1 class="title">{{ category.name }}</h1>

      <div class="item-list">
        <div class="item-list__item" v-for="item in products[category.id]" :key="item.id">
          <div class="item-list__item__title">{{item.name}}</div>
          <img
            loading="lazy"
            width="400"
            height="400"
            class="item-list__item__image"
            @click="selectedProduct = item"
            :alt="item.name"
            :src="item.images[0]"
          />
        </div>
      </div>

      <div v-for="subCategory in category.items" :key="subCategory.id">
        <h2 class="title">{{subCategory.name}}</h2>

        <div class="item-list">
          <div class="item-list__item" v-for="item2 in products[subCategory.id]" :key="item2.id">
            <div class="item-list__item__title">{{item2.name}}</div>
            <img
              loading="lazy"
              width="400"
              height="400"
              class="item-list__item__image"
              @click="selectedProduct = item"
              :alt="item2.name"
              :src="item2.images[0]"
            />
          </div>
        </div>

        <div v-for="subCategory2 in subCategory.items" :key="subCategory2.id">
          <h3 class="title">{{subCategory2.name}}</h3>

          <div class="item-list">
            <div class="item-list__item" v-for="item3 in products[subCategory2.id]" :key="item3.id">
              <div class="item-list__item__title">{{item3.name}}</div>
              <img
                loading="lazy"
                width="400"
                height="400"
                class="item-list__item__image"
                @click="selectedProduct = item"
                :alt="item3.name"
                :src="item3.images[0]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="#popup-target">
    <div v-show="selectedProduct">
      <ModalCarousel
        @close-modal="selectedProduct = undefined;"
        :images="(selectedProduct && selectedProduct.images) || []"
      ></ModalCarousel>
    </div>
  </teleport>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { menuItems, getMenuItem } from "@/util/menu-items";
import ModalCarousel from "@/components/modal-carousel.vue";
import useProducts from "@/hooks/useProducts";

export default {
  components: {
    ModalCarousel,
  },
  setup() {
    const route = useRoute();
    const currentCategoryId = computed(() => route.params.productId);
    const category = computed(() => getMenuItem(currentCategoryId.value));
    const productsState = useProducts();
    const selectedProduct = ref();

    return {
      currentCategoryId,
      category,
      products: productsState.getGroupedProducts,
      selectedProduct,
    };
  },
};
</script>
<style scoped lang="scss">
.page-body {
  .title {
    padding-left: 5px;
    text-transform: capitalize;
  }

  img {
    cursor: pointer;
  }

  @media screen and (max-width: 900px) {
    h1.title {
      font-size: 30px;
      padding: 0;
      margin: 0;
      margin-top: 11px;
    }

    h2.title {
      font-size: 24px;
      padding: 0;
      margin: 0;
      margin-top: 5px;
    }

    h3.title {
      font-size: 20px;
      padding: 0;
      margin: 0;
      margin-top: 5px;
    }
  }

  .item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      &__image {
        margin: 5px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media screen and (max-width: 400px) {
          width: 100vw;
          height: 100vw;
        }
      }

      &__title {
        margin-top: 10px;
        padding-left: 5px;
      }
    }
  }
}
</style>