<template>
  <div class="create">+</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.create {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 50px;
  cursor: pointer;

  &:hover {
    color: black;
  }

  &:active {
    font-size: 46px;
    transition: font-size 0.1s;
  }
}
</style>
