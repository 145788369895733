<script>
import { computed, ref, onUnmounted } from "vue";
import { onSnapshot } from "firebase/firestore";
import { homeImagesCollection } from "@/firebase";

export default function() {
  const homeImages = ref([]);

  const removeListener = onSnapshot(homeImagesCollection, async (images) => {
    homeImages.value = [];

    for (let image of images.docs) {
      const data = image.data();
      homeImages.value.push({ id: image.id, ...data });
    }
  });

  onUnmounted(() => {
    removeListener();
  });

  const getHomeImages = computed(() => homeImages.value);

  return {
    homeImages: getHomeImages,
  };
}
</script>
