<template>
  <div class="page">
    <div class="page-body">
      <h1>Avís legal</h1>
      <p>De conformitat amb la Llei 34/2002, de Serveis de la Societat de la Informació i de Comerç Electrònic, l'informem que aquest web és titularitat d'Angelina Ginestera, (en endavant “LA TITULAR”), amb CIF 77900651-L, domicili a Galeries Guimerà Perpinyà local 12 de Banyoles (17820); telèfon 972 57 57 02.</p>

      <h2>Propietat intel·lectual i industrial</h2>
      <p>Els continguts d'aquest lloc, incloent-hi els textos, imatges i dissenys gràfics, pertanyen a LA TITULAR, o a tercers que han autoritzat el seu ús. LA TITULAR presenta aquests continguts amb finalitats d'informació i promoció. LA TITULAR autoritza la seva utilització exclusivament amb aquestes finalitats. Qualsevol utilització d'aquests dissenys, imatges o textos haurà de citar expressament la seva pertinença a LA TITULAR, qui es reserva el dret a iniciar les accions legals oportunes per reparar els danys i perjudicis causats per qualsevol acte que vulneri els seus drets de propietat intel·lectual o industrial.</p>

      <h2>Exclusió de responsabilitat</h2>
      <p>
        LA TITULAR actua amb la màxima diligència possible perquè les dades i la informació que ofereix al seu lloc web estigui actualitzada en tot moment, si bé no garanteix ni es fa responsable de l'exactitud i actualització dels continguts del lloc web, reservant-se el dret a modificar aquests continguts en qualsevol moment. LA TITULAR tampoc serà responsable de la informació que es pot obtenir a través d'enllaços inclosos en el lloc web.
        Les relacions comercials amb els clients es regiran per les condicions generals que, si escau, s'estableixin per LA TITULAR en un document específic a aquest efecte, o pels pactes concrets que es puguin acordar amb els clients.
      </p>

      <h2>Condicions d'ús</h2>
      <p>L'ús d'aquest lloc web implica l'acceptació plena dels termes del present avís legal. Els possibles conflictes relatius a aquest web es regiran exclusivament pel dret de l'Estat Espanyol. Tota persona usuària del web, independentment de la jurisdicció territorial des de la qual es produeixi el seu accés, accepta el compliment i respecte d'aquestes clàusules amb renúncia expressa a qualsevol altre fur que pogués correspondre-li.</p>
    </div>
  </div>
</template>

<script>
</script>

<style scoped lang="scss">
</style>
