<template>
  <div class="page">
    <div class="page-body">
      <h1>Il·luminació Ginestera</h1>
      <div class="page-body__container">
        <Carousel :images="images" />
        <div class="page-body__container__info">
          <p style="margin-top: 0">Il·luminació Ginestera és una empresa familiar dedicada al món de la il·luminació des de 1980. Especialitzats en la venda de làmpades i productes d"il·luminació.</p>
          <p>El nostre principal objectiu és que els nostres clients estiguin ben informats i quedin satisfets dels nostres productes.</p>
          <p>A la nostra botiga hi podeu trobar tot tipus de làmpades, bombetes, pantalles, tulipes i material elèctric. També ens dediquem a la reparació de làmpades.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import useHomeImages from "@/hooks/useHomeImages";
import Carousel from "@/components/carousel.vue";

export default {
  components: {
    Carousel,
  },
  setup() {
    const { homeImages } = useHomeImages();

    const images = computed(() => homeImages.value.map((i) => i.url));

    return {
      images,
    };
  },
};
</script>

<style scoped lang="scss">
.page-body {
  &__container {
    display: flex;
    padding-top: 25px;

    img {
      min-width: 300px;
      min-height: 300px;
      margin-right: 20px;
    }

    &__info {
      margin-left: 20px;
    }

    @media screen and (max-width: 600px) {
      padding-top: 10px;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        margin-right: 0;
      }

      &__info {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
</style>
