<template>
  <div class="modal" :class="{wait: wait}">
    <div class="body">
      <div class="content">
        <slot></slot>
      </div>
      <div class="footer">
        <button @click="closeModal" :disabled="wait">Cancel·lar</button>
        <button @click="saveModal" :disabled="wait">Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    wait: Boolean,
  },
  setup(props, { emit }) {
    const wait = computed(() => props.wait);

    function closeModal() {
      emit("close-modal");
    }

    function saveModal() {
      emit("save-modal");
    }

    return {
      closeModal,
      saveModal,
      wait,
    };
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: absolute;
  width: calc(100% - 200px);
  height: calc(100% - 290px);
  top: 60px;
  padding: 100px;
  backdrop-filter: blur(2px);

  .body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    background: white;
    height: 100%;
    border: 1px solid black;

    .content {
      padding: 10px;
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      button {
        margin-bottom: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.wait {
  cursor: wait;

  button {
    cursor: wait !important;
  }
}
</style>
