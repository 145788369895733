<script>
import { computed, ref, onUnmounted } from "vue";
import { groupBy, sortBy } from "lodash-es";
import { query, orderBy, onSnapshot } from "firebase/firestore";
import { productsCollection, storage } from "@/firebase";
import { categories } from "@/util/menu-items";

export default function() {
  const products = ref([]);

  const removeListener = onSnapshot(query(
    productsCollection,
    orderBy("updated", "desc")
  ), async (ps) => {
    products.value = [];

    for (let product of ps.docs) {
      const data = product.data();
      data.id = product.id;

      data.categoryName = categories.find(
        (cat) => cat.id === data.category
      ).name;

      products.value.push(data);
    }
  });

  onUnmounted(() => {
    removeListener();
  });

  const getProducts = computed(() => products.value);

  const getProductsSortedByCategory = computed(() =>
    sortBy(products.value, ["category"])
  );

  const getGroupedProducts = computed(() =>
    groupBy(products.value, "category")
  );

  return {
    getProducts,
    getProductsSortedByCategory,
    getGroupedProducts,
  };
}
</script>
