<template>
  <div class="page">
    <div class="page-body">
      <label for="email">E-mail:</label>
      <br />
      <input type="text" id="email" name="email" v-model="username" />
      <br />
      <label for="password">Contrassenya:</label>
      <br />
      <input type="password" id="password" name="password" v-model="password" />
      <br />
      <br />
      <button type="button" @click="login">Login</button>
    </div>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import useAuth from "@/hooks/useAuth";
import useLogin from "@/hooks/useLogin";
import router from "@/router.js";
import { useRoute } from "vue-router";

export default {
  setup(props, context) {
    const state = useLogin();
    const authState = useAuth();
    const route = useRoute();
    const toRouteName = computed(() => route.params.to || "admin");

    watch(authState.user, (currentUser) => {
      if (currentUser) {
        router.push({ name: toRouteName.value });
      }
    });

    return { ...state };
  },
};
</script>

<style scoped lang="scss">
.page {
  margin-top: 20px;
}
</style>
