<script>
import { computed, ref, reactive, onUnmounted } from "vue";
import { query, orderBy, onSnapshot } from "firebase/firestore";
import { suppliersCollection } from "@/firebase";

export default function() {
  const suppliers = ref({});
  const suppliersArray = ref([]);

  const removeListener = onSnapshot(
    query(suppliersCollection, orderBy("name")),
    async (supplierList) => {
      suppliers.value = {};
      suppliersArray.value = [];

      for (let supplier of supplierList.docs) {
        const data = supplier.data();
        suppliers.value[supplier.id] = data;
        suppliersArray.value.push({ id: supplier.id, ...data });
      }
    }
  );

  onUnmounted(() => {
    removeListener();
  });

  const getSuppliers = computed(() => suppliers.value);
  const getSuppliersArray = computed(() => suppliersArray.value);

  return {
    suppliers: getSuppliers,
    suppliersArray: getSuppliersArray,
  };
}
</script>
