<template>
  <div class="footer">
    <router-link :to="{ name: 'legal' }">Avís legal</router-link>
    <router-link :to="{ name: 'privacy' }">Política de privacitat</router-link>
    <router-link :to="{ name: 'cookies' }">Política de cookies</router-link>
  </div>
</template>

<script>
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
  height: 30px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 900px) {
  .footer {
    display: none;
  }
}
</style>
