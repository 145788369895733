import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, collection, query, memoryLocalCache } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyDCEn4xhNIjBkwGgn_93-j1QHwNM0ZR764",
  authDomain: "illuminacioginestera.firebaseapp.com",
  databaseURL: "https://illuminacioginestera.firebaseio.com",
  projectId: "illuminacioginestera",
  storageBucket: "illuminacioginestera.appspot.com",
  messagingSenderId: "401871545551",
  appId: "1:401871545551:web:c3505170bf1a8f282143ba",
};
const firebaseApp = initializeApp(firebaseConfig);

// utils
const db = initializeFirestore(firebaseApp, { localCache: memoryLocalCache() });
const storage = getStorage(firebaseApp);

const auth = getAuth(firebaseApp);
auth.languageCode = "ca";

// collection references
const productsCollection = query(collection(db, "products"));
const suppliersCollection = query(collection(db, "suppliers"));
const homeImagesCollection = query(collection(db, "home-images"));

// export utils/refs
export { auth, productsCollection, suppliersCollection, homeImagesCollection, storage };
