<template>
  <div
    class="sidebar"
    :class="{ open: displayed }"
    @click.stop
    v-click-away="onClickOutside"
  >
    <div class="sidebar__inner">
      <ul class="sidebar__inner__menu">
        <li
          v-for="menuItem in menuItems"
          :key="menuItem.id"
          class="sidebar__inner__menu__item"
          @click.stop="hideSidebar()"
        >
          <h3 :class="{ current: currentCategoryId === menuItem.id }">
            <router-link
              :to="{ name: menuItem.page, params: { productId: menuItem.id } }"
              >{{ menuItem.name }}</router-link
            >
          </h3>
          <ul v-if="menuItem.items">
            <li
              v-for="subcategory in menuItem.items"
              :key="subcategory.id"
              @click.stop="hideSidebar()"
            >
              <p :class="{ current: currentCategoryId === subcategory.id }">
                <router-link
                  :to="{
                    name: subcategory.page,
                    params: { productId: subcategory.id },
                  }"
                  >{{ subcategory.name }}</router-link
                >
              </p>
              <ul v-if="subcategory.items">
                <li
                  v-for="subcategory2 in subcategory.items"
                  :key="subcategory2.id"
                  @click.stop="hideSidebar()"
                >
                  <p
                    :class="{ current: currentCategoryId === subcategory2.id }"
                  >
                    <router-link
                      :to="{
                        name: subcategory2.page,
                        params: { productId: subcategory2.id },
                      }"
                      >{{ subcategory2.name }}</router-link
                    >
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="sidebar__inner__menu__item" v-if="user">
          <h3>
            <router-link
              @click="logout"
              :to="{ name: 'home', params: { productId: 'home' } }"
              >Logout</router-link
            >
          </h3>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import VueClickAway from "vue3-click-away";
import { menuItems } from "@/util/menu-items";
import useAuth from "@/hooks/useAuth";
import useLogin from "@/hooks/useLogin";

export default {
  directives: {
    ClickAway: VueClickAway,
  },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const currentCategoryId = computed(() => route.params.productId);
    const authState = useAuth();
    const loginState = useLogin();

    const copiedItems = JSON.parse(JSON.stringify(menuItems));

    copiedItems.find((item) => item.id === "contacte").items = [
      { id: "legal", page: "legal", name: "Avís Legal" },
      { id: "privacy", page: "privacy", name: "Política de privacitat" },
      { id: "cookies", page: "cookies", name: "Política de cookies" },
    ];

    function onClickOutside() {
      hideSidebar();
    };

    function hideSidebar() {
      emit("hide");
    }

    return {
      menuItems: copiedItems,
      currentCategoryId,
      hideSidebar,
      onClickOutside,
      logout: loginState.logout,
      ...authState,
    };
  },
};
</script>
<style scoped lang="scss">
$menu-selected: #bdbdbd;
$menu-link-font-color: #636363;

.sidebar {
  position: absolute;
  z-index: 4;
  background-color: #f9f9f9;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  overflow-y: auto;

  transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
  -webkit-transform: translate(-280px, 0);
  transform: translate(-280px, 0);

  &__inner {
    padding: 50px 10px 10px 20px;
    box-sizing: border-box;
    width: 260px;

    a {
      color: #636b6f;
      text-transform: capitalize;

      &:link,
      &:visited,
      &:hover,
      &:active {
        text-decoration: none;
      }

      &:hover {
        border-bottom: 3px solid $menu-selected;
      }
    }

    ul {
      list-style-type: none;
    }

    &__menu {
      padding-left: 0;
      list-style-type: none;
      margin: 0;
      line-height: 1.5em;

      &__item {
        &:first-letter {
          text-transform: capitalize;
        }

        ul {
          padding-left: 15px;
        }

        h3 {
          font-weight: 600;
          color: #2c3e50;
          font-size: 1.17em;
          -webkit-margin-before: 1em;
          -webkit-margin-after: 1em;
          -webkit-margin-start: 0px;
          -webkit-margin-end: 0px;
        }
      }
    }
  }

  .current {
    & > h3,
    a {
      color: $menu-link-font-color;
      border-bottom: 3px solid $menu-selected;
    }
  }

  &.open {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
</style>
