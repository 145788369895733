<template>
  <div class="page">
    <div class="page-body">
      <h1>Contacte</h1>
      <div class="page-body__container">
        <div class="vue-map-container">
          <iframe
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.openstreetmap.org/export/embed.html?bbox=2.7640293369293217%2C42.116774585829645%2C2.76631550693512%2C42.117703282813124&amp;layer=mapnik&amp;marker=42.117213940561825%2C2.7651724219322205"
            style="border: 1px solid black; width: 100%; height: 100%;"
          ></iframe>
          <br />
          <small>
            <a
              href="https://www.openstreetmap.org/?mlat=42.11721&amp;mlon=2.76517#map=19/42.11721/2.76517"
            >Mostra un mapa més gran</a>
          </small>
        </div>

        <div class="contact">
          <div class="social-media">
            <a href="https://www.instagram.com/illuminacioginestera/" class="instagram" />
            <a href="https://www.facebook.com/illuminacioginestera/" class="fb" />
          </div>
          <p>
            Galeries Guimerà Perpinya local 12
            <br />17820 Banyoles (Girona)
          </p>
          <p>
            <a href="tel:972575702">(+34) 972 57 57 02</a>
          </p>
          <p>
            <a href="tel:621274407">(+34) 621 274 407</a>
          </p>
          <p>
            <a href="mailto:iginestera@gmail.com" target="_top">iginestera@gmail.com</a>
          </p>
          <p>
            Horari
            <br />Dilluns de 16.30 h a 20:00 h
            <br />Dimarts - divendres de 9.15 h a 13.00 h i de 16.30 h a 20:00 h
            <br />Dissabte de 9.15 h a 13.00 h i de 17.00 h a 20:00 h
            <br />Diumenge tancat
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped lang="scss">
.page-body {
  &__container {
    display: flex;

    .vue-map-container {
      width: 500px;
      height: 300px;
    }

    @media screen and (max-width: 900px) {
      flex-grow: 2;

      .vue-map-container {
        width: initial;
      }
    }

    .contact {
      margin-left: 20px;

      i {
        margin-right: 5px;
      }

      .social-media {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;

        a {
          display: block;
          margin-right: 5px;
          text-indent: -9999px;
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
        }

        .fb {
          background-image: url(../../assets/fb.svg);
        }

        .instagram {
          background-image: url(../../assets/instagram.svg);
        }
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;

      .contact {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>
