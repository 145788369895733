<template>
  <div class="page">
    <div class="page-body">
      <div class="links">
        <router-link :to="{ name: 'admin', params: { }}">Productes</router-link>
        <router-link :to="{ name: 'admin-home-images', params: { }}">Imatges inici</router-link>
      </div>
      <div class="controls">
        <input type="text" v-model="searchText" />
        <AddButton @click="isPopUpOpen = true"/>
        <teleport to="#popup-target">
          <div v-show="isPopUpOpen">
            <Modal
              @close-modal="isPopUpOpen = false; editedSupplierId = ''; newSupplierName = ''"
              @save-modal="saveSupplier"
            >
              <label for="name">Nom:</label>
              <br />
              <input type="text" id="name" name="name" v-model="newSupplierName" />
            </Modal>
          </div>
        </teleport>
      </div>

      <div class="item-list">
        <div class="item-list__item" v-for="item in suppliers" :key="item.id">
          <div>{{item.name}}</div>
          <button @click="() => editSupplier(item)">Editar</button>
          <button @click="() => removeSupplier(item)">Eliminar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { serverTimestamp} from "firebase/firestore";
import { suppliersCollection } from "@/firebase";
import useSuppliers from "@/hooks/useSuppliers";
import AddButton from "@/components/add-button.vue";
import Modal from "@/components/modal.vue";

export default {
  components: {
    AddButton,
    Modal,
  },
  setup() {
    const searchText = ref("");
    const newSupplierName = ref("");
    const editedSupplierId = ref("");
    const isPopUpOpen = ref(false);
    const suppliersState = useSuppliers();

    const filteredSuppliers = computed(() => {
      const text = searchText.value.toLowerCase();

      return suppliersState.suppliersArray.value.filter((p) =>
        p.name.toLowerCase().includes(text)
      );
    });

    function saveSupplier() {
      let promise = editedSupplierId.value.length
        ? suppliersCollection.doc(editedSupplierId.value).update({
            name: newSupplierName.value,
            updated: serverTimestamp(),
          })
        : suppliersCollection.add({
            name: newSupplierName.value,
            updated: serverTimestamp(),
          });

      promise
        .then(function (docRef) {
          console.log("Document saved: ", docRef && docRef.id);
        })
        .catch(function (error) {
          console.error("Error saving document: ", error);
        });

      isPopUpOpen.value = false;
      newSupplierName.value = "";
      editedSupplierId.value = "";
    }

    function removeSupplier(item) {
      if (confirm(`Vols eliminar ${item.name}`)) {
        suppliersCollection
          .doc(item.id)
          .delete()
          .then(function () {
            console.log("Document successfully deleted!");
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    }

    function editSupplier(item) {
      isPopUpOpen.value = true;
      editedSupplierId.value = item.id;
      newSupplierName.value = item.name;
    }

    return {
      suppliers: filteredSuppliers,
      searchText,
      isPopUpOpen,
      newSupplierName,
      saveSupplier,
      removeSupplier,
      editSupplier,
    };
  },
};
</script>

<style scoped lang="scss">
.controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.links {
  > * {
    margin-right: 10px;
  }
}

.item-list {
  &__item {
    display: grid;
    grid-template-columns: 200px 100px 100px;
    column-gap: 10px;
    margin-bottom: 5px;
  }
}
</style>
