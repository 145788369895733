<template>
  <div class="header" @mouseleave="mouseLeave">
    <div
      class="header__logo"
      @click="goHome"
      :style="{'background-image': `url(${require('../assets/header-logo.jpg')})`}"
    />
    <div
      v-for="menuItem in menuItems"
      :key="menuItem.id"
      class="header__item"
      :class="{ 'header__item--current': currentCategoryRootId === menuItem.id }"
      @mouseover.stop="mouseOver(menuItem)"
    >
      <router-link
        :to="{ name: menuItem.page, params: { productId: menuItem.id }}"
      >{{ menuItem.name }}</router-link>
      <span class="arrow" v-if="menuItem.items"></span>
      <ul v-if="menuItem.items" v-show="menuItem.id === mouseOverCategory" @mouseleave="mouseLeave">
        <li
          v-for="subcategory in menuItem.items"
          :key="subcategory.id"
          :class="{ 'current': currentCategoryId === subcategory.id }"
        >
          <router-link
            :to="{name: subcategory.page, params: { productId: subcategory.id }}"
          >{{ subcategory.name }}</router-link>
          <ul v-if="subcategory.items">
            <li
              v-for="subcategory2 in subcategory.items"
              :key="subcategory2.id"
              :class="{ 'current': currentCategoryId === subcategory2.id }"
            >
              <router-link
                :to="{name: subcategory2.page, params: { productId: subcategory2.id }}"
              >{{ subcategory2.name }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="header__item" v-if="user">
      <router-link @click="logout" :to="{ name: 'home', params: { productId: 'home' }}">Logout</router-link>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router.js";
import { menuItems } from "@/util/menu-items";
import useAuth from "@/hooks/useAuth";
import useLogin from "@/hooks/useLogin";

export default {
  setup() {
    const mouseOverCategory = ref(undefined);
    const route = useRoute();
    const currentCategoryId = computed(() => route.params.productId);
    const authState = useAuth();
    const loginState = useLogin();

    // If root item contains a '-' this should be refactored into a recursive search.
    const currentCategoryRootId = computed(() => {
      const value = currentCategoryId.value || "";
      return value.split(/-(.+)/)[0];
    });

    function mouseOver(category) {
      mouseOverCategory.value = category.id;
    }

    function mouseLeave() {
      mouseOverCategory.value = undefined;
    }

    function goHome() {
      router.push({ name: "home" });
    }

    return {
      menuItems,
      mouseOverCategory,
      currentCategoryId,
      currentCategoryRootId,
      logout: loginState.logout,
      mouseOver,
      mouseLeave,
      goHome,
      ...authState,
    };
  },
};
</script>

<style scoped lang="scss">
// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Colors.
$menu-selected: #bdbdbd;
$menu-link-font-color: #636363;

.header {
  position: relative;
  width: 100%;
  height: 60px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__item {
    position: relative;
    padding: 10px;
    text-transform: capitalize;

    &--current {
      > a {
        border-bottom: 3px solid $menu-selected;
      }
    }

    .arrow {
      display: inline-block;
      vertical-align: middle;
      margin-top: -1px;
      margin-left: 5px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #ccc;
    }

    a {
      color: $menu-link-font-color;

      &:link,
      &:visited,
      &:hover,
      &:active {
        text-decoration: none;
      }
    }

    ul {
      list-style-type: none;
      z-index: 1;
    }

    li {
      &.current {
        & > a {
          font-weight: bold;
        }
      }

      a {
        &:visited,
        &:hover,
        &:active {
          color: $menu-link-font-color;
          font-weight: bold;
        }
      }
    }

    & > a {
      &:hover {
        border-bottom: 3px solid $menu-selected;
      }
    }

    & > ul {
      box-sizing: border-box;
      max-height: calc(100vh - 61px);
      overflow-y: auto;
      position: absolute;
      top: 21px;
      right: 15px;
      width: 170px;
      background-color: #fff;
      padding: 15px;
      border: 1px solid #ddd;
      border-bottom-color: #ccc;
      text-align: left;
      border-radius: 3px;
      white-space: nowrap;

      & > li:not(:last-child) {
        margin-bottom: 5px;
      }

      ul {
        padding-left: 15px;

        li {
          margin-top: 3px;

          a {
            font-size: 13px;
          }
        }
      }
    }
  }

  &__logo {
    height: 100%;
    width: 150px;
    margin-right: auto;
    margin-left: 10px;
    cursor: pointer;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 900px) {
  .header {
    display: none;
  }
}
</style>
