<template>
  <div class="modal">
    <div class="body">
      <span class="close" @click="closeModal()">&times;</span>
      <div class="content" ref="slidesContainer">
        <div v-for="image in images" :key="image" class="slide">
          <img loading="lazy" :src="image" width="300" height="300" />
        </div>
      </div>
      <a class="prev" @click="plusSlides(-1)">&#10094;</a>
      <a class="next" @click="plusSlides(1)">&#10095;</a>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const slidesContainer = ref();
    const slideIndex = ref(1);

    watch(
      () => props.images,
      (images) => {
        showSlides(slideIndex.value);
      }
    );

    function closeModal() {
      emit("close-modal");
    }

    function plusSlides(n) {
      showSlides((slideIndex.value += n));
    }

    function currentSlide(n) {
      showSlides((slideIndex.value = n));
    }

    function showSlides(n) {
      const slides = slidesContainer.value.children;
      if (slides.length === 0) {
        return;
      }

      if (n > slides.length) {
        slideIndex.value = 1;
      }
      if (n < 1) {
        slideIndex.value = slides.length;
      }

      for (let slide of slides) {
        slide.style.display = "none";
      }

      slides[slideIndex.value - 1].style.display = "flex";
    }

    return { closeModal, plusSlides, slidesContainer };
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: absolute;
  width: calc(100% - 200px);
  height: calc(100% - 290px);
  top: 60px;
  padding: 100px;
  backdrop-filter: blur(2px);

  .body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    background: white;
    height: 100%;
    border: 1px solid black;

    .close {
      position: absolute;
      top: 110px;
      right: 115px;
      font-size: 35px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: #999;
      text-decoration: none;
      cursor: pointer;
    }

    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      padding: 16px;
      margin-top: -50px;
      font-weight: bold;
      font-size: 20px;
      transition: 0.6s ease;
      user-select: none;
      -webkit-user-select: none;
    }

    .next {
      right: 99px;
    }

    .prev:hover,
    .next:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .content {
      height: 100%;
      padding: 10px;

      .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .modal {
    top: 41px;
    width: 100%;
    height: 100%;
    padding: 0;

    .body {
      border: none;

      .close {
        top: 10px;
        right: 10px;
        font-size: 60px;
        font-weight: 400;
      }

      .next {
        right: 0;
      }
    }
  }
}
</style>
