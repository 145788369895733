<template>
  <div class="page">
    <div class="page-body">
      <div class="links">
        <router-link :to="{ name: 'admin-suppliers', params: { }}">Proveïdors</router-link>
        <router-link :to="{ name: 'admin', params: { }}">Productes</router-link>
      </div>
      <div class="controls">
        <AddButton @click="() => fileInput.click()" />
        <input
          type="file"
          accept="image/*"
          multiple
          @change="uploadImages"
          style="display: none;"
          ref="fileInput"
        />
      </div>

      <div class="item-list">
        <div class="item-list__item" v-for="image in homeImages" :key="image.id">
          <img loading="lazy" alt :src="image.url" width="200" height="200" />
          <button @click="() => removeImage(image)">Eliminar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { serverTimestamp} from "firebase/firestore";
import shortid from "shortid";
import { homeImagesCollection, storage } from "@/firebase";
import AddButton from "@/components/add-button.vue";
import useHomeImages from "@/hooks/useHomeImages";

export default {
  components: {
    AddButton,
  },
  setup() {
    const { homeImages } = useHomeImages();
    const fileInput = ref();

    async function removeImage(item) {
      if (confirm(`Vols eliminar la imatge?`)) {
        await storage.refFromURL(item.url).delete();
        
        homeImagesCollection
          .doc(item.id)
          .delete()
          .then(function () {
            console.log("Document successfully deleted!");
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    }

    async function uploadImages(event) {
      const newMetadata = {
        cacheControl: "public, max-age=31536000",
      };

      for (let file of event.target.files) {
        const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);
        const id = shortid.generate();

        const imageRef = storage.ref().child(`home/${id}.${fileExtension}`);
        await imageRef.put(file, newMetadata);
        const url = await imageRef.getDownloadURL();

        await homeImagesCollection.doc(id).set({
          url,
          updated: serverTimestamp(),
        });
      }
    }

    return {
      fileInput,
      homeImages,
      removeImage,
      uploadImages,
    };
  },
};
</script>

<style scoped lang="scss">
.controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.links {
  > * {
    margin-right: 10px;
  }
}

.item-list {
  &__item {
    display: grid;
    grid-template-columns: 200px 100px 100px;
    column-gap: 10px;
    margin-bottom: 5px;
  }
}
</style>
