<template>
  <div class="slideshow-container" ref="slidesContainer">
    <div v-for="image in images" :key="image" class="slide fade">
      <img loading="lazy" :src="image" width="300" height="300" />
    </div>
  </div>
  <br />
</template>

<script>
import { ref, watch, onUnmounted } from "vue";
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const slidesContainer = ref();
    const slideIndex = ref(0);
    const timer = ref();

    function showSlides() {
      const slides = slidesContainer.value.children;
      if (slides.length === 0) {
        return;
      }

      for (let slide of slides) {
        slide.style.display = "none";
      }

      slideIndex.value++;
      if (slideIndex.value > slides.length) {
        slideIndex.value = 1;
      }

      slides[slideIndex.value - 1].style.display = "block";
      timer.value = setTimeout(showSlides, 5000);
    }

    watch(
      () => props.images,
      (images) => {
        if (timer.value) {
          clearTimeout(timer.value);
        } 
        setTimeout(() => showSlides());
      }
    );

    onUnmounted(() => {
      clearTimeout(timer.value);
    });

    return { slidesContainer };
  },
};
</script>

<style scoped lang="scss">
.slide {
  display: none;
}

img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
</style>
