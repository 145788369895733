import { flatMap } from "lodash-es";

/**
 * To parametrize this for other apps it could be stored in a DB.
 * Here it's hardcoded since it is only used by a single app and thus, we avoid calls.
 */
const categories = [
  { id: "interior", name: "Interior" },
  { id: "exterior", name: "Exterior" },
  { id: "complements", name: "Complements" },
  { id: "interior-aplics", parent: "interior", name: "Aplics" },
  { id: "interior-empotrables", parent: "interior", name: "Empotrables" },
  {
    id: "interior-escriptori-o-despatx",
    parent: "interior",
    name: "Escriptori o despatx",
  },
  { id: "interior-focus", parent: "interior", name: "Focus" },
  { id: "interior-penjants", parent: "interior", name: "Penjants" },
  { id: "interior-peus", parent: "interior", name: "Peus" },
  { id: "interior-plafons", parent: "interior", name: "Plafons" },
  { id: "interior-sobretaula", parent: "interior", name: "Sobretaula" },
  { id: "interior-ventiladors", parent: "interior", name: "Ventiladors" },
  {
    id: "interior-ventiladors-amb-llum",
    parent: "interior-ventiladors",
    name: "Amb llum",
  },
  {
    id: "interior-ventiladors-sense-llum",
    parent: "interior-ventiladors",
    name: "Sense llum",
  },
  { id: "complements-bombetes", parent: "complements", name: "Bombetes" },
  {
    id: "complements-bombetes-led",
    parent: "complements-bombetes",
    name: "Led",
  },
  {
    id: "complements-bombetes-baix-consum",
    parent: "complements-bombetes",
    name: "Baix consum",
  },
  {
    id: "complements-bombetes-halogen",
    parent: "complements-bombetes",
    name: "Halogen",
  },
  {
    id: "complements-bombetes-incandescent",
    parent: "complements-bombetes",
    name: "Incandescent",
  },
  {
    id: "complements-material-electric",
    parent: "complements",
    name: "Material elèctric",
  },
  {
    id: "complements-cable-decoratiu",
    parent: "complements",
    name: "Cable decoratiu",
  },
  { id: "complements-piles", parent: "complements", name: "Piles" },
  { id: "complements-tulipes", parent: "complements", name: "Tulipes" },
  {
    id: "complements-tulipes-linia-classica",
    parent: "complements-tulipes",
    name: "Línia clàssica",
  },
  {
    id: "complements-tulipes-linia-moderna",
    parent: "complements-tulipes",
    name: "Línia moderna",
  },
  {
    id: "complements-tulipes-opal-triplex",
    parent: "complements-tulipes",
    name: "Opal triplex",
  },
  { id: "complements-pantalles", parent: "complements", name: "Pantalles" },
  {
    id: "interior-portatils-amb-bateria",
    parent: "interior",
    name: "Portàtils amb bateria",
  },
  { id: "exterior-aplic", parent: "exterior", name: "Aplic" },
  { id: "exterior-balisa", parent: "exterior", name: "Balisa" },
  { id: "exterior-empotrables", parent: "exterior", name: "Empotrables" },
  { id: "exterior-foco", parent: "exterior", name: "Focus" },
  { id: "exterior-penjant", parent: "exterior", name: "Penjant" },
  { id: "exterior-plafo", parent: "exterior", name: "Plafó" },
  { id: "exterior-sobremur", parent: "exterior", name: "Sobremur" },
  { id: "exterior-altres", parent: "exterior", name: "Altres" },
  { id: "complements-llanterna", parent: "complements", name: "Llanterna" },
];

// Generate menu items from categories.
const menuItems = [];
for (let cat of categories) {
  if (!cat.parent) {
    menuItems.push({
      id: cat.id,
      name: cat.name,
      page: "products",
    });
  } else {
    const parent = flatMap(menuItems, (menuItem) => {
      if (menuItem.items) {
        return [...menuItem.items, menuItem];
      } else {
        return [menuItem];
      }
    }).find((menuItem) => menuItem.id === cat.parent);

    if (!parent.items) {
      parent.items = [];
    }

    parent.items.push({
      id: cat.id,
      name: cat.name,
      page: "products",
    });
  }
}
menuItems.push({ id: "contacte", page: "contact", name: "Contacte" });

/**
 * To get a menu item from its id.
 * @param {String} id. Item we are looking for.
 */
function getMenuItem(id) {
  return deepSearch(menuItems, id);
}

//
// Helpers
//

/**
 * Recursive search for a specific item by its id.
 * @param {Array} items. MenuItems array.
 * @param {String} id. Item we are looking for.
 */
function deepSearch(items, id) {
  if (!items) {
    return;
  }

  for (let item of items) {
    const menuItem = item.id === id ? item : deepSearch(item.items, id);

    if (menuItem) {
      return menuItem;
    }
  }
}

export { categories, menuItems, getMenuItem };
