import { createRouter, createWebHistory } from "vue-router";
import { auth } from "@/firebase";
import Home from "@/views/client/Home.vue";
import Contact from "@/views/client/Contact.vue";
import Cookies from "@/views/client/Cookies.vue";
import Legal from "@/views/client/Legal.vue";
import Privacy from "@/views/client/Privacy.vue";
import Products from "@/views/client/Products.vue";
import Login from "@/views/admin/Login.vue";
import Admin from "@/views/admin/Admin.vue";
import HomeImages from "@/views/admin/HomeImages.vue";
import Suppliers from "@/views/admin/Suppliers.vue";

const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/productes/:productId",
      name: "products",
      component: Products,
    },
    {
      path: "/contacte",
      name: "contact",
      component: Contact,
    },
    {
      path: "/login/:to?",
      name: "login",
      component: Login,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin-home-images',
      name: 'admin-home-images',
      component: HomeImages,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin-suppliers',
      name: 'admin-suppliers',
      component: Suppliers,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/politica-de-cookies",
      name: "cookies",
      component: Cookies,
    },
    {
      path: "/avis-legal",
      name: "legal",
      component: Legal,
    },
    {
      path: "/politica-de-privacitat",
      name: "privacy",
      component: Privacy,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next(`/login/${to.name}`)
  } else {
    next()
  }
})

export default router;
