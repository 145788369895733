<template>
  <div class="page">
    <div class="page-body">
      <button class="backup-button" @click="downloadBackup">Baixar backup</button>
      <div class="links">
        <router-link :to="{ name: 'admin-suppliers', params: { }}">Proveïdors</router-link>
        <router-link :to="{ name: 'admin-home-images', params: { }}">Imatges inici</router-link>
      </div>
      <div class="controls">
        <input type="text" v-model="searchText" />

        <select v-model="selectedCategory">
          <option value>Filtrar per categoria</option>
          <option
            :value="category.id"
            v-for="category in categories"
            :key="category.id"
          >{{category.id}}</option>
        </select>

        <select v-model="selectedSupplier">
          <option value>Filtrar per proveïdor</option>
          <option
            :value="supplierId"
            v-for="(supplier, supplierId) in suppliers"
            :key="supplierId"
          >{{supplier.name}}</option>
        </select>

        <AddButton @click="isPopUpOpen = true" />
        <teleport to="#popup-target">
          <div v-show="isPopUpOpen">
            <Modal
              :wait="savingData"
              @close-modal="resetNewProduct(); isPopUpOpen = false;"
              @save-modal="saveProduct"
            >
              <label for="name">Nom:</label>
              <br />
              <input type="text" id="name" name="name" v-model="newProduct.name" />
              <br />
              <br />

              <label for="category">Categoria:</label>
              <br />
              <select id="category" name="category" v-model="newProduct.category">
                <option disabled value>Seleccionar categoria</option>
                <option
                  :value="category.id"
                  v-for="category in categories"
                  :key="category.id"
                >{{category.id}}</option>
              </select>
              <br />
              <br />

              <label for="supplier">Proveïdor:</label>
              <br />
              <select id="supplier" name="supplier" v-model="newProduct.supplier">
                <option disabled value>Seleccionar proveïdor</option>
                <option
                  :value="supplierId"
                  v-for="(supplier, supplierId) in suppliers"
                  :key="supplierId"
                >{{supplier.name}}</option>
              </select>
              <br />
              <br />

              <label for="mainimage">Imatge principal:</label>
              <div v-if="newProduct.mainImage && typeof newProduct.mainImage === 'string'">
                <img
                  loading="lazy"
                  :alt="newProduct.name"
                  :src="newProduct.mainImage"
                  width="100"
                  height="100"
                />
              </div>
              <br />
              <input
                type="file"
                id="mainimage"
                name="mainimage"
                accept="image/*"
                @change="setMainImage"
              />
              <br />
              <br />

              <label for="images">Altres imatges:</label>
              <div class="images">
                <div v-for="(image, index) in newProduct.images" :key="index">
                  <img
                    v-if="typeof image === 'string'"
                    loading="lazy"
                    :alt="newProduct.name"
                    :src="image"
                    width="100"
                    height="100"
                  />
                </div>
              </div>
              <br />
              <input
                type="file"
                id="images"
                name="images"
                accept="image/*"
                multiple
                @change="setImages"
              />
              <br />
            </Modal>
          </div>
        </teleport>
      </div>

      <div class="item-list">
        <div class="item-list__item" v-for="item in products" :key="item.id">
          <!-- <img loading="lazy" :alt="item.name" :src="item.images[0]" width="100" height="100" /> -->
          <div></div>
          <div>{{item.name}}</div>
          <div>{{item.categoryName}}</div>
          <div>{{suppliers[item.supplier] && suppliers[item.supplier].name}}</div>
          <button @click="() => editProduct(item)">Editar</button>
          <button @click="() => removeProduct(item)">Eliminar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from "vue";
import shortid from "shortid";
import { serverTimestamp} from "firebase/firestore";
import { productsCollection, storage } from "@/firebase";
import { categories } from "@/util/menu-items";
import AddButton from "@/components/add-button.vue";
import Modal from "@/components/modal.vue";
import useProducts from "@/hooks/useProducts";
import useSuppliers from "@/hooks/useSuppliers";
import useHomeImages from "@/hooks/useHomeImages";

export default {
  components: {
    AddButton,
    Modal,
  },
  setup() {
    const searchText = ref("");
    const selectedCategory = ref("");
    const selectedSupplier = ref("");
    const productsState = useProducts();
    const suppliersState = useSuppliers();
    const { homeImages } = useHomeImages();
    const isPopUpOpen = ref(false);
    const savingData = ref(false);
    const editedProductId = ref("");
    const newProduct = reactive({
      name: "",
      category: "",
      supplier: "",
      mainImage: "",
      images: [],
    });
    const imagesToDelete = ref([]);

    const filteredProducts = computed(() => {
      const text = searchText.value.toLowerCase();
      const category = selectedCategory.value.toLowerCase();
      const supplier = selectedSupplier.value;

      return productsState.getProducts.value
        .filter(
          (p) =>
            p.name.toLowerCase().includes(text) ||
            p.category.toLowerCase().includes(text) ||
            (p.supplier && p.supplier.toLowerCase().includes(text))
        )
        .filter((p) => category.length === 0 || p.category === category)
        .filter((p) => supplier.length === 0 || p.supplier === supplier);
    });

    function setMainImage(event) {
      if (newProduct.mainImage) {
        imagesToDelete.value.push(newProduct.mainImage);
      }
      newProduct.mainImage = event.target.files[0];
    }

    function setImages(event) {
      if (newProduct.images.length) {
        imagesToDelete.value = imagesToDelete.value.concat(newProduct.images);
      }
      newProduct.images = event.target.files;
    }

    async function uploadFile(file, productId) {
      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);
      const newMetadata = {
        cacheControl: "public, max-age=31536000",
      };

      const imageRef = storage
        .ref()
        .child(`products/${productId}/${shortid.generate()}.${fileExtension}`);
      await imageRef.put(file, newMetadata);
      return imageRef.getDownloadURL();
    }

    async function saveProduct() {
      savingData.value = true;
      if (!newProduct.name) {
        alert("Falta el nom.");
        savingData.value = false;
        return;
      }

      if (!newProduct.category) {
        alert("Falta la categoria.");
        savingData.value = false;
        return;
      }

      if (!newProduct.mainImage) {
        alert("Falta la imatge principal.");
        savingData.value = false;
        return;
      }

      for (let imageToDelete of imagesToDelete.value) {
        await storage.refFromURL(imageToDelete).delete();
      }

      const productId = editedProductId.value.length
        ? editedProductId.value
        : shortid.generate();

      // Create new images.
      const imageUrls =
        typeof newProduct.mainImage === "string"
          ? [newProduct.mainImage]
          : [await uploadFile(newProduct.mainImage, productId)];

      if (
        newProduct.images.length &&
        typeof newProduct.images[0] === "string"
      ) {
        for (let imageUrl of newProduct.images) {
          imageUrls.push(imageUrl);
        }
      } else if (newProduct.images.length) {
        for (let imageFile of newProduct.images) {
          imageUrls.push(await uploadFile(imageFile, productId));
        }
      }

      // Save / update.
      productsCollection
        .doc(productId)
        .set({
          name: newProduct.name,
          category: newProduct.category,
          supplier: newProduct.supplier,
          images: imageUrls,
          updated: serverTimestamp(),
        })
        .then(function (docRef) {
          console.log("Document saved: ", productId);
        })
        .catch(function (error) {
          console.error("Error saving document: ", error);
        });

      resetNewProduct();
      isPopUpOpen.value = false;
      savingData.value = false;
    }

    async function removeProduct(item) {
      if (confirm(`Vols eliminar ${item.name}`)) {
        for (let imageToDelete of item.images) {
          await storage.refFromURL(imageToDelete).delete();
        }

        productsCollection
          .doc(item.id)
          .delete()
          .then(function () {
            console.log("Document successfully deleted!");
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    }

    function editProduct(item) {
      isPopUpOpen.value = true;
      editedProductId.value = item.id;
      newProduct.name = item.name;
      newProduct.category = item.category;
      newProduct.supplier = item.supplier;
      newProduct.mainImage = item.images[0];
      newProduct.images = item.images.slice(1);
      imagesToDelete.value = [];
    }

    function resetNewProduct() {
      newProduct.name = "";
      newProduct.category = "";
      newProduct.supplier = "";
      newProduct.mainImage = "";
      newProduct.images = [];
      imagesToDelete.value = [];
      document.getElementById("mainimage").value = "";
      document.getElementById("images").value = "";
    }

    function backup(items, name) {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(items));
      const linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataStr);
      linkElement.setAttribute("download", `${name}.json`);
      linkElement.click();
      linkElement.remove();
    }

    function downloadBackup() {
      const r = confirm("Vols descarregar un backup de tot?");
      if (r === true) {
        backup(productsState.getProducts.value, "products-backup.json");
        backup(suppliersState.suppliers.value, "suppliers-backup.json");
        backup(homeImages.value, "homeimages-backup.json");
      }
    }

    return {
      categories,
      products: filteredProducts,
      searchText,
      selectedCategory,
      selectedSupplier,
      suppliers: suppliersState.suppliers,
      isPopUpOpen,
      savingData,
      newProduct,
      setMainImage,
      setImages,
      saveProduct,
      removeProduct,
      editProduct,
      resetNewProduct,
      downloadBackup,
    };
  },
};
</script>

<style scoped lang="scss">
button.backup-button {
  margin: 10px 0 10px 0;
}
.controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > * {
    margin-right: 10px;
  }
}

.links {
  > * {
    margin-right: 10px;
  }
}

.item-list {
  &__item {
    display: grid;
    grid-template-columns: 100px 200px 200px 200px 100px 100px;
    column-gap: 10px;
    margin-bottom: 5px;
  }
}

.images {
  display: flex;
}
</style>
