<template>
  <div class="header">
    <div class="button" @click.stop="showSidebar">
      <div class="button__line" />
      <div class="button__line" />
      <div class="button__line" />
    </div>
    <div
      class="logo"
      :style="{'background-image': `url(${require('../assets/icon.jpg')})`}"
      @click="goHome"
    />
  </div>
  <Sidebar :displayed="sidebarDisplayed" @hide="hideSidebar" />
</template>

<script>
import { ref } from "vue";
import Sidebar from "./menu-mobile-sidebar.vue";
import router from "@/router.js";

export default {
  components: { Sidebar },
  setup() {
    const sidebarDisplayed = ref(false);

    function goHome() {
      router.push({ name: "home" });
    }

    function hideSidebar() {
      sidebarDisplayed.value = false;
    }

    function showSidebar() {
      sidebarDisplayed.value = true;
    }

    return { sidebarDisplayed, goHome, hideSidebar, showSidebar };
  }
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  width: 100%;
  height: 40px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

  .button {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40px;
    box-sizing: border-box;
    cursor: pointer;

    padding: {
      top: 7px;
      bottom: 8px;
      left: 8px;
      right: 8px;
    }

    &__line {
      height: 3px;
      background-color: black;
    }
  }

  .logo {
    position: absolute;
    width: 30px;
    height: 30px;
    background: center center no-repeat;
    top: 5px;
    left: 50%;
    margin-left: -15px;
    background-size: 30px;
    cursor: pointer;
  }
}

@media screen and (min-width: 900px) {
  .header {
    display: none;
  }
}
</style>
