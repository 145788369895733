<template>
  <div class="page">
    <div class="page-body">
      <h1>Política de privacitat</h1>
      <p>LA TITULAR es compromet a protegir la privacitat dels usuaris que accedeixin a aquest web i/o qualsevol dels seus serveis. La utilització del web i/o de qualsevol dels serveis oferts per LA TITULAR, implica l'acceptació per part de l''usuari de les disposicions contingudes en la present Política de Privacitat i que les seves dades personals siguin tractades segons s'hi estipula. Si us plau, tingui en compte que tot i que hi pugui haver enllaços del nostre web a d'altres webs, aquesta Política de Privacitat no s'aplica als webs d'altres companyies o organitzacions a les quals el web estigui redirigit. LA TITULAR no controla el contingut dels webs de tercers ni accepta cap tipus de responsabilitat pel contingut o les polítiques de privacitat d'aquests webs.</p>

      <b>Informació sobre el tractament de dades (Reglament (UE) 2016/679 i LO 3/2018)</b>
      <p>
        Responsable del tractament: LA TITULAR. Les nostres dades figuren a l'
        <router-link :to="{ name: 'legal' }">Avís legal</router-link>d'aquest web.
      </p>
      <p>Finalitat del tractament: Oferir i gestionar els nostres productes i/o serveis.</p>
      <p>Legitimació: Consentiment obtingut de l'interessat. Execució del contracte de serveis.</p>
      <p>Destinataris: Les dades no seran comunicades a tercers, llevat que ho exigeixi una llei o sigui necessari per complir amb la finalitat del tractament.</p>
      <p>Drets de les persones: Els interessats tenen dret a exercir els drets d'accés, rectificació, limitació de tractament, supressió, portabilitat i oposició, enviant la seva sol·licitud a la nostra l'adreça.</p>
      <p>Termini de conservació de les dades: Mentre es mantingui la relació comercial o durant els anys necessaris per complir amb les obligacions legals.</p>
      <p>Reclamació: Els interessats es poden dirigir a l'AEPD per presentar la reclamació que consideri oportuna.</p>
      <p>Informació addicional: Pot consultar la informació addicional i detallada a continuació a les “Qüestions sobre privacitat”.</p>

      <h2>Qüestions sobre privacitat</h2>
      <p>En compliment del Reglament (UE) 2016/679 del Parlament Europeu i del Consell, de 27 d'abril de 2016, (RGPD) i la Llei Orgànica 3/2018, de 5 de desembre, de Protecció de Dades Personals i garantia dels drets digitals (LOPDGDD), li oferim la següent informació sobre el tractament de les seves dades personals:</p>

      <b>Qui és el responsable del tractament de les seves dades?</b>
      <p>
        LA TITULAR. Les nostres dades figuren a l'
        <router-link :to="{ name: 'legal' }">Avís legal</router-link>d'aquest web.
      </p>

      <b>Amb quina finalitat tractem les seves dades personals?</b>
      <p>Tractem la informació que se'ns facilita per prestar i facturar els nostres serveis i productes.</p>

      <b>Durant quant de temps conservarem les seves dades?</b>
      <p>Les dades personals proporcionades es conservaran mentre sigui usuari dels nostres serveis o en vulgui rebre informació, i després, durant el terminis establerts per complir amb les nostres obligacions legals.</p>

      <b>Quina és la legitimació per al tractament de les seves dades?</b>
      <p>La base legal per als tractaments de les seves dades són els consentiments que ens dóna. Respecte a la informació que ens sigui enviada per menors de 16 anys, s'entendrà que ho ha estat amb el consentiment dels seus representants legals. Si no és així, el representant legal del menor ens ho ha de comunicar tan aviat com en tingui coneixement.</p>

      <b>A quins destinataris es comunicaran les seves dades?</b>
      <p>Les dades no seran comunicades a tercers, llevat que ho exigeixi una llei o sigui necessari per complir amb la finalitat del tractament.</p>

      <b>Quins són els seus drets quan ens facilita les seves dades?</b>
      <ul>
        <li>Qualsevol persona té dret a obtenir confirmació sobre si estem tractant o no les seves dades personals.</li>
        <li>Les persones interessades tenen dret a accedir a les seves dades personals, així com a sol·licitar la rectificació de les dades inexactes o, si escau, sol·licitar la seva supressió quan, entre d'altres motius, les dades ja no siguin necessàries per a les finalitats per a les que es van recollir.</li>
        <li>En determinades circumstàncies els interessats podran sol·licitar la limitació del tractament de les seves dades, en aquest cas únicament les conservarem per a l'exercici o la defensa de reclamacions.</li>
        <li>També, en determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats es poden oposar al tractament de les seves dades. En aquest cas deixarem de tractar-les, excepte per motius legítims imperiosos o per a l'exercici o la defensa de possibles reclamacions.</li>
        <li>Els interessats també tenen dret a la portabilitat de les seves dades.</li>
        <li>Finalment, els interessats tenen dret a presentar una reclamació davant l'Autoritat de Control competent.</li>
      </ul>

      <b>Com pot exercir els seus drets?</b>
      <p>Enviant-nos un escrit adjuntant una còpia d'un document que l'identifiqui, a la nostra adreça física o a l'electrònica.</p>

      <b>Com hem obtingut les seves dades?</b>
      <p>Les dades personals que tractem procedeixen del propi interessat, qui garanteix que les dades personals facilitades són certes i es fa responsable de comunicar-ne qualsevol modificació. Les dades que estiguin marcades amb un asterisc són obligatòries per poder donar-li el servei sol·licitat.</p>

      <b>Quines dades tractem?</b>
      <p>Les categories de dades que podem tractar són:</p>
      <ul>
        <li>Dades de caràcter identificatiu.</li>
        <li>Adreces postals o electròniques.</li>
        <li>Altres dades sol·licitades en els nostres formularis.</li>
      </ul>
      <p>Les dades són limitades, atès que únicament tractem les dades necessàries per a la prestació dels nostres serveis i la gestió de la nostra activitat.</p>

      <b>Utilitzem cookies?</b>
      <p>
        Utilitzem cookies durant la navegació a la nostra web amb el consentiment informat de l'usuari. L'usuari pot configurar el seu navegador perquè l'avisi de la utilització de cookies i per evitar-ne el seu ús. Per obtenir més informació visiti la nostra
        <router-link :to="{ name: 'cookies' }">política de cookies</router-link>.
      </p>

      <b>Quines mesures de seguretat apliquem?</b>
      <p>
        Apliquem les mesures de seguretat establertes a l'article 32 del RGPD, per tant hem adoptat les mesures de seguretat necessàries per garantir un nivell de seguretat adequat al risc del tractament de dades que realitzem, amb mecanismes que ens permeten garantir la confidencialitat, integritat, disponibilitat i resiliència permanent dels sistemes i serveis de tractament.
        Algunes d'aquestes mesures són:
      </p>
      <ul>
        <li>Informació de les polítiques de tractament de dades al personal.</li>
        <li>Realització de còpies de seguretat periòdiques.</li>
        <li>Control d'accés a les dades.</li>
        <li>Processos de verificació, avaluació i valoració regulars.</li>
      </ul>
    </div>
  </div>
</template>

<script>
</script>

<style scoped lang="scss">
</style>
