<template>
  <MenuDesktop />
  <MenuMobile />
  <router-view />
  <div id="popup-target"></div>
  <FooterDesktop v-if="currentPage === 'contact'" />
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import FooterDesktop from "./components/footer-desktop.vue";
import MenuDesktop from "./components/menu-desktop.vue";
import MenuMobile from "./components/menu-mobile.vue";

export default {
  name: "App",
  components: {
    FooterDesktop,
    MenuDesktop,
    MenuMobile,
  },
  setup() {
    const route = useRoute();
    const currentPage = computed(() => route.name);

    return { currentPage };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b4b4b4;
}

* {
  color: rgb(99, 107, 111);
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 22.4px;

  scrollbar-color: #c4c4c4 transparent;
  scrollbar-width: thin;
}

body {
  margin: 0;
  overflow: hidden;
  min-width: 340px;
}

h1,
h2,
h3 {
  padding-left: 5px;
  text-transform: capitalize;
  margin-top: 22px;
  margin-bottom: 11px;
  font-weight: 500;
  line-height: 1.1;
}

h1 {
  padding-left: 0;
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

button {
  cursor: pointer;
}

select {
  height: 28px;
}

.page {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 62px);

  .page-body {
    max-width: 1300px;
    margin: auto;
  }
}

@media screen and (max-width: 900px) {
  .page {
    max-height: calc(100vh - 42px);
  }
}
</style>
