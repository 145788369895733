<template>
  <div class="page">
    <div class="page-body">
      <h1>Política de cookies</h1>
      <p>El nostre lloc web illuminacioginestera.com utilitza una tecnologia anomenada 'cookie', amb la finalitat de poder obtenir informació sobre l'ús del lloc web, millorar l'experiència de l'usuari i garantir el seu correcte funcionament.</p>

      <b>Definició de Cookie</b>
      <p>
        Una 'cookie' és un petit fragment de text que els llocs web envien al navegador i que s'emmagatzemen en el terminal de l'usuari, (ordinador o dispositiu mòbil). Aquests arxius tenen la finalitat d'emmagatzemar dades que podran ser actualitzades i recuperades per l'entitat responsable de la seva instal·lació. Així, cada vegada que l'usuari torna a visitar el mateix lloc web, l'explorador recupera l'arxiu i l'envia al servidor del lloc web. Les cookies tenen diverses funcions entre les que s'inclouen, la de recollir informació sobre les preferències de l'usuari i recordar-la, i en general, facilitar l'ús del lloc web a l'usuari fent que el lloc sigui més útil al personalitzar el seu contingut.
        Les cookies es poden classificar en base a diferents paràmetres:
      </p>
      <ol>
        <li>Titularitat: cookies pròpies i cookies de tercers: depenent de quina sigui l'entitat que gestioni el domini des d'on s'envien les cookies i es tractin les dades que s'obtinguin.</li>
        <li>Durada: cookies de sessió i cookies persistents: d'acord amb el termini de temps que romanen emmagatzemades al terminal de l'usuari.</li>
        <li>Finalitat: cookies tècniques, cookies de personalització, cookies d'anàlisi, cookies publicitàries, cookies de publicitat comportamental: d'acord amb la finalitat per a la qual es tractin les dades obtingudes.</li>
        <ul>
          <li>Cookies tècniques: són aquelles que permeten a l'usuari la navegació a través de la pàgina web o aplicació i la utilització de les diferents opcions o serveis que en ella existeixen. Per exemple, controlar el trànsit i la comunicació de dades , identificar la sessió o accedir a les parts web d'accés restringit.</li>
          <li>Cookies de personalització: són aquelles que permeten a l'usuari accedir al servei amb algunes característiques de caràcter general predefinides en el seu terminal o que el propi usuari defineixi . Per exemple, l'idioma , el tipus de navegador a través del qual accedeix al servei o el disseny de continguts seleccionat.</li>
          <li>Cookies d'anàlisi: són aquelles que o bé tractades per nosaltres o per tercers, ens permeten quantificar el nombre d'usuaris i així realitzar l'anàlisi estadístic de la utilització que fan els usuaris del servei ofert. Per això s'analitza la seva navegació a la nostra pàgina web per tal de millorar l'oferta de productes o serveis que li oferim.</li>
          <li>Cookies publicitàries: són aquelles que permeten la gestió, de la forma més eficaç possible, dels espais publicitaris que, si escau, l'editor hagi inclòs en una pàgina web, aplicació o plataforma des de la qual presta el servei sol·licitat sobre la base de criteris com el contingut editat o la freqüència en a qual es mostren els anuncis.</li>
          <li>Cookies de publicitat comportamental: són aquelles que permeten la gestió, de la manera més eficaç possible, dels espais publicitaris que, si escau, l'editor hagi inclòs en una pàgina web, aplicació o plataforma des de la que presta el servei sol·licitat. Aquestes cookies emmagatzemen informació del comportament dels usuaris obtinguda a través de l'observació continuada dels seus hàbits de navegació, el que permet desenvolupar un perfil específic per mostrar publicitat en funció del mateix.</li>
        </ul>
      </ol>

      <b>Cookies utilitzades al nostre lloc web</b>
      <p>
        El nostre lloc web utilitza tant cookies pròpies, que són les enviades al seu dispositiu i gestionades exclusivament per nosaltres per al millor funcionament del lloc web, com cookies de tercers, amb la funció de servir-nos com a eina d'anàlisis de la interacció dels usuaris amb la nostra web, gràcies a elles obtenim una informació que pot ajudar-nos a millorar la navegació i donar un millor servei als usuaris.
        El nostre lloc web fa servir tant cookies temporals de sessió com cookies persistents. Les cookies de sessió emmagatzemen dades únicament mentre l'usuari accedeix a la web i les cookies persistents emmagatzemen les dades en el terminal perquè siguin accedides i utilitzades en més d'una sessió.
        Concretament les cookies que utilitzades al nostre lloc web són:
      </p>
      <u>Cookies pròpies</u>
      <p>Les cookies pròpies que fem servir en el nostre lloc web són de caràcter tècnic, fonamentals per al funcionament de la web.</p>
      <u>Cookies de tercers</u>
      <ul>
        <li>Cookies: __utmz, __ utmc, __utma, __utmb,__utmt, __ga, __gat</li>
        <li>Titular: Google Inc. (Google Analytics)</li>
        <li>Finalitat: Analítiques: Analitzen l'ús del web.</li>
        <li>Durada: Sessió i persistent</li>
      </ul>
      <p>
        Per obtenir més informació sobre les cookies i/o per a la seva desactivació pot clicar sobre els enllaços que hi ha al nom dels diferents titulars.
        Finalment, també s'utilitzen galetes de complement (plug-in) per intercanviar continguts socials.
      </p>

      <b>Consentiment</b>
      <p>
        Al navegar i continuar al nostre lloc web l'usuari estarà consentint l'ús de les cookies abans enunciades, pels terminis assenyalats i en les condicions contingudes en la present Política de Cookies.
        Així mateix, l'informem que si vostè consulta continguts de la nostra web allotjats en webs de tercers, aquests tenen la seva pròpia política de cookies.
      </p>

      <b>Retirada del consentiment</b>
      <p>L'usuari podrà retirar en qualsevol moment el seu consentiment relacionat amb aquesta Política de Cookies, per això haurà d'eliminar les cookies emmagatzemades en el seu equip utilitzant els ajustaments i configuracions del seu navegador d'Internet.</p>

      <b>Modificació de la configuració de les cookies</b>
      <p>L'usuari pot restringir, bloquejar o esborrar les cookies de qualsevol lloc web, utilitzant el seu navegador. A cada navegador l'operativa és diferent, pel que pot consultar als següents enllaços la forma de fer-ho</p>
      <ul>
        <li>
          <a
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
          >Internet Explorer</a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
          >Edge</a>
        </li>
        <li>
          <a href="https://support.mozilla.org/es/kb/clear-cookies-and-site-data-firefox">FireFox</a>
        </li>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=ca">Chrome</a>
        </li>
        <li>
          <a
            href="https://support.apple.com/es-es/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          >Safari (mac)</a>
        </li>
        <li>
          <a href="https://support.apple.com/es-es/HT201265">Safari (iPhone/iPad)</a>
        </li>
        <li>
          <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>
        </li>
      </ul>

      <b>Actualització de la política de cookies</b>
      <p>Aquest lloc web pot modificar aquesta Política sobre Cookies, per això li recomanem revisar aquesta política cada vegada que accedeixi al nostre lloc web per tal d'estar adequadament informat sobre l'ús que fem de les cookies.</p>
    </div>
  </div>
</template>

<script>
</script>

<style scoped lang="scss">
</style>
