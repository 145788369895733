<script>
import { toRefs, reactive, computed } from "vue";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getApp } from "firebase/app";

export default function () {
  let state = reactive({
    error: null,
    username: null,
    password: null,
    user: null,
  });

  /**
   * have this value `isValid` get updated when the dependent properties
   * are changed in the composition function
   */
  let isValid = computed(() => {
    let { username, password } = state;
    return (
      username !== null &&
      username.length !== 0 &&
      password !== null &&
      password.length !== 0
    );
  });


  const firebaseApp = getApp();
  const auth = getAuth(firebaseApp);

  const login = () => {
    signInWithEmailAndPassword(auth, state.username, state.password)
      .then(
        (res) => {
          state.user = res.user;
        },
        (error) => (state.error = error)
      )
      .catch((error) => {
        state.error = error;
      });
  };

  const logout = () => {
    signOut(auth)
      .then(() => {})
      .catch((error) => {
        state.error = error;
      });
  };

  return {
    // return all of the properties from the function
    ...toRefs(state),

    // return all of the computed value to make sure we
    // get non null values for username/password from the
    // function
    isValid,

    // pass back a login and logout function to be utilized
    // by the login form
    login,
    logout,
  };
}
</script>
